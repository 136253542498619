<template>
    <div class="w-filter-wrapper">
        <div class="w-filter-wrapper__header">
            <div class="w-filter-wrapper__title">
                <el-icon class="u-icon"><Filter /></el-icon>
                <span class="u-title">{{ title }}</span>
            </div>
            <span class="u-collapse" @click="collapse" :class="{ on: fold }">
                <el-icon size="14"><DCaret /></el-icon>
                <span class="u-collapse-text">{{ fold ? "展开" : "折叠" }}</span>
            </span>
        </div>
        <div class="w-filter-wrapper__body" :class="{ on: fold }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "FilterWrapper",
    props: {
        title: {
            type: String,
            default: "条件筛选",
        },
        fold: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:fold"],
    computed: {},
    methods: {
        collapse: function () {
            this.$emit("update:fold", !this.fold);
        },
    },
};
</script>

<style lang="less">
@import "~@/assets/css/widget/filter_wrapper.less";
</style>
