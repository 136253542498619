// 获取文档标题
const { Title } = require("@/settings");
import { getCdnLink } from "@deepberry/common/js/utils";

export function getPageTitle(pageTitle) {
    if (pageTitle) {
        return `${pageTitle} - ${Title}`;
    }
    return Title || "Admin";
}

export function resolveImagePath(url) {
    if (!url) return "";
    // 将 http://deepberry.oss-cn-zhangjiakou.aliyuncs.com 替换为空 也可能是https
    const path = url?.replace(/http:\/\/deepberry.oss-cn-zhangjiakou.aliyuncs.com\//g, "");
    return getCdnLink(path);
}
