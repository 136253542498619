import { Local, Session } from "@/utils/storage";

const state = {
    checkedDevices: [],
};

const mutations = {
    SET_DEVICE(state, device) {
        const i = state.checkedDevices.findIndex((c) => c.id === device.id);
        if (i === -1) {
            state.checkedDevices.unshift(device);
        } else {
            state.checkedDevices.splice(i, 1);
        }
    },
    SET_DEVICES(state, { devices, isDel = false }) {
        if (isDel) {
            const ids = devices.map((item) => item.id);
            state.checkedDevices = state.checkedDevices.filter((item) => !ids.includes(item.id));
            return;
        }
        // add
        if (!state.checkedDevices.length) {
            state.checkedDevices = devices;
            return;
        }
        const existIds = state.checkedDevices.map((item) => item.id);
        const deviceList = devices.filter((item) => !existIds.includes(item.id));
        state.checkedDevices = deviceList.concat(state.checkedDevices);
    },
};

const actions = {
    setDevice({ commit }, device) {
        commit("SET_DEVICE", device);
    },
    setDevices({ commit }, data) {
        commit("SET_DEVICES", data);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
