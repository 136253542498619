export default {
    mounted(el, binding, vnode, prevVnode) {
        const userAgent = navigator.userAgent;
        const elClass = el.classList?.[0] || "is";
        // 判断设备类型
        if (/Mobi|Android/i.test(userAgent)) {
            // 移动端设备
            el.classList.add(`${elClass}__mobile`);
        } else {
            // PC端设备
            el.classList.add(`${elClass}__pc`);
        }
    },
};
