export default [
    {
        path: "/portable",
        name: "portable__root__",
        component: () => import("@/layouts/blank_layout.vue"),
        hidden: true,
        children: [
            {
                name: "produce_inventory",
                path: "produce/inventory",
                component: () => import("@/views/portable/produce_inventory.vue"),
                meta: {
                    title: "商品出入库",
                    icon: "index",
                    affix: false,
                },
            },
        ],
    },
];
