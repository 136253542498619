import { Local, Session } from "@/utils/storage";
import { getGroupList, getMyPermission } from "@/service/account";
import User from "@/utils/user";
import { getMessageUnRead } from "@/service/message";

const state = {
    sidebar: {
        opened: true,
        withoutAnimation: false,
    },
    device: "desktop",
    size: Local.get("size") || "medium",

    userGroup: [], // 用户组
    user: {
        info: {}, // 信息
        permission: [], // 权限
    },

    unReadCount: 0,

    counts: {},

    isMobile: false,
    isMobileNavShow: false,
};

const mutations = {
    SET_MOBILE(state, val) {
        state.isMobile = val;
        if (val) {
            state.sidebar.opened = false;
            state.sidebar.withoutAnimation = true;
            Local.set("sidebarStatus", 0);
        }
    },
    SET_MOBILE_NAV_SHOW(state, val) {
        state.isMobileNavShow = val;
    },
    TOGGLE_SIDEBAR: (state) => {
        state.sidebar.opened = !state.sidebar.opened;
        state.sidebar.withoutAnimation = false;
        if (state.sidebar.opened) {
            Local.set("sidebarStatus", 1);
        } else {
            Local.set("sidebarStatus", 0);
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Local.set("sidebarStatus", 0);
        state.sidebar.opened = false;
        state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device;
    },
    SET_SIZE: (state, size) => {
        state.size = size;
        Local.set("size", size);
    },
    SET_SIDEBAR_STATUS: (state, status) => {
        state.sidebar.opened = status;
        if (status) {
            Local.set("sidebarStatus", 1);
        } else {
            Local.set("sidebarStatus", 0);
        }
    },
    SET_USER_GROUP: (state, userGroup) => {
        state.userGroup = userGroup;
    },
    INIT_USER: (state, user) => {
        state.user = user;
        Local.set("permission", user.permission);
        Local.set("group", user.info.group);
    },
    SET_COUNTS(state, { key, value }) {
        state.counts[key] = value;
    },
    SET_UNREAD_COUNT: (state, unReadCount) => {
        state.unReadCount = unReadCount;
    },
};

const actions = {
    toggleSideBar({ commit }) {
        commit("TOGGLE_SIDEBAR");
    },
    closeSideBar({ commit }, { withoutAnimation }) {
        commit("CLOSE_SIDEBAR", withoutAnimation);
    },
    toggleDevice({ commit }, device) {
        commit("TOGGLE_DEVICE", device);
    },
    setSize({ commit }, size) {
        commit("SET_SIZE", size);
    },
    loadGroupList({ commit }, refresh) {
        // 需要重新加载
        if (refresh) {
            Session.remove("groupList");
        }
        try {
            const groupList = Session.get("groupList");
            if (groupList) {
                commit("SET_USER_GROUP", groupList);
            } else {
                getGroupList().then((res) => {
                    commit("SET_USER_GROUP", res.data.data);
                    Session.set("groupList", res.data.data);
                });
            }
        } catch (error) {
            console.log(error);
        }
    },
    //  加载用户信息
    async loadMyPermission({ commit }) {
        if (!User.isLogin()) {
            return User.logout();
        }
        if (User.isLogin()) {
            await getMyPermission().then((res) => {
                const user = res.data.data || {};
                commit("INIT_USER", user);
            });
        }
    },
    async loadMessageCount({ commit }) {
        await getMessageUnRead().then((res) => {
            const count = res.data.data || 0;
            commit("SET_UNREAD_COUNT", count);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
