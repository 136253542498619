export default {
    button: {
        save: "Save",
        submit: "Submit",
        confirm: "Confirm",
        select: "Select",
        cancel: "Cancel",
        add: "Add",
        remove: "Remove",
        edit: "Edit",
    },
    tip: {
        submit_success: "Submit Success",
        save_success: "Save Success",
        operate_success: "Operate Success",
    },
    widget: {
        fold_block: {
            expand: "Expand",
            collapse: "Collapse",
            default: "Filter",
        },
    },
};
