import { $cms } from "@/utils/https.js";
// 意向订单
export function createIntention(data) {
    return $cms().post(`/api/cms/order/intention`, data);
}

export function getIntentions(params) {
    return $cms().get(`/api/cms/order/intention`, { params });
}

export function updateIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}`, data);
}

export function deleteIntention(id) {
    return $cms().delete(`/api/cms/order/intention/${id}`);
}

export function getIntention(id) {
    return $cms().get(`/api/cms/order/intention/${id}`);
}

// 意向订单 开始洽谈
export function startIntention(id) {
    return $cms().put(`/api/cms/order/intention/${id}/start`);
}

// 意向订单 开始签约
export function startSignIntention(id) {
    return $cms().put(`/api/cms/order/intention/${id}/start_sign`);
}

// 保存意向订单
export function saveIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/save`, data);
}

// 意向订单 签约
export function signIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/sign`, data);
}

// 关闭意向订单
export function closeIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/close`, data);
}

// 提交审批
export function submitApprovalIntention(id, data) {
    return $cms().post(`/api/cms/order/intention/${id}/approve`, data);
}

// 获取审批详情
export function getApprovalIntention(params) {
    return $cms().get(`/api/cms/ewx/approve`, { params });
}

// 移交订单
export function transferIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/transfer`, data);
}

// 获取我的意向订单统计
export function getMyIntentionCount(params) {
    return $cms().get(`/api/cms/order/intention/count/mine`, { params });
}

// 获取工单列表统计
export function getIntentionCount(params) {
    return $cms().get(`/api/cms/order/intention/group/stat`, { params });
}

// 获取订单日志
export function getIntentionLogs(order_id, params) {
    return $cms().get(`/api/cms/order/intention/log/${order_id}`, { params });
}

// 修改订单日志
export function changeIntentionLog(id, data) {
    return $cms().put(`/api/cms/order/intention/log/${id}`, data);
}

// 获取收款日志
export function getReceiptLogs(params) {
    return $cms().get(`/api/cms/order/receipt_log`, { params });
}

// 备注收款日志
export function remarkReceiptLog(id, data) {
    return $cms().put(`/api/cms/order/receipt_log/${id}/remark`, data);
}

// 录入订单
export function entryIntention(data) {
    return $cms().post(`/api/cms/order/intention/entry`, data);
}

// 更新录入订单 不能修改状态
export function entryUpdateIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/entry`, data);
}

// 生产
export function produceIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/produce`, data);
}

// 生产
export function produceBindIntention(id, task_id) {
    return $cms().put(`/api/cms/order/intention/${id}/produce-bind/${task_id}`);
}

// 发货
export function deliverIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/deliver`, data);
}

// 意向订单 安装
export function installIntention(id) {
    return $cms().put(`/api/cms/order/intention/${id}/install`);
}

// 意向订单 调试
export function debugIntention(id) {
    return $cms().put(`/api/cms/order/intention/${id}/debug`);
}

// 意向订单 验收
export function checkIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/check`, data);
}

// 意向订单 确认
export function confirmIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/confirm`, data);
}

// 意向订单 完成
export function finishIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/finish`, data);
}

// 意向订单 收款
export function receiptIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/receipt`, data);
}

// 意向订单 开票
export function invoiceIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/invoice`, data);
}

// 意向订单 归档
export function archiveIntention(id, data) {
    return $cms().put(`/api/cms/order/intention/${id}/archive`, data);
}
