import { $cms } from "@/utils/https.js";

export function createGoodsCategory(data) {
    return $cms().post(`/api/cms/manage/mall/goods_category`, data);
}

export function getGoodsCategories(params) {
    return $cms().get(`/api/cms/manage/mall/goods_category`, { params });
}

export function updateGoodsCategory(id, data) {
    return $cms().put(`/api/cms/manage/mall/goods_category/${id}`, data);
}

export function deleteGoodsCategory(id) {
    return $cms().delete(`/api/cms/manage/mall/goods_category/${id}`);
}

export function getGoodsCategory(id) {
    return $cms().get(`/api/cms/manage/mall/goods_category/${id}`);
}
