export default [
    {
        path: "/eurofins",
        name: "eurofins__root__",
        redirect: {
            name: "eurofins-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "eurofins",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "eurofins-index",
                component: () => import("@/views/eurofins/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                    permission: "manage_eurofins",
                },
            },
        ],
    },
    {
        path: "/eurofins/system",
        name: "eurofins-system",
        meta: {
            title: "系统配置",
            icon: "system",
            parent: "eurofins",
            permission: "manage_eurofins_system",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/eurofins/system/program",
        children: [
            {
                path: "program",
                name: "eurofins-system-program",
                component: () => import("@/views/eurofins/system/program.vue"),
                meta: {
                    title: "方案管理",
                    icon: "types",
                },
            },
            {
                path: "guide",
                name: "eurofins-system-guide",
                component: () => import("@/views/eurofins/system/guide.vue"),
                meta: {
                    title: "操作指引",
                    icon: "guide",
                },
            },
            {
                path: "document",
                name: "documentEurofinsList",
                component: () => import("@/views/cms/source/document"),
                meta: {
                    title: "文档中心",
                    icon: "document",
                    affix: false,
                    type: "eurofins",
                },
            },
            {
                path: "detail/:id(\\d+)?",
                name: "eurofinsDocument",
                hidden: true,
                component: () => import("@/views/cms/source/document/document_form2.vue"),
                meta: {
                    title: "文档内容",
                    icon: "document",
                    affix: false,
                    activeMenu: "/eurofins/system/document",
                },
            },
        ],
    },
    {
        path: "/eurofins/sensing",
        name: "eurofins-sensing",
        meta: {
            title: "检测方案",
            icon: "sensing",
            parent: "eurofins",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/eurofins/sensing/warehouse",
        children: [
            {
                path: "warehouse",
                name: "eurofins-sensing-warehouse",
                component: () => import("@/views/eurofins/sensing/warehouse.vue"),
                meta: {
                    title: "入库处理",
                    icon: "warehouse",
                    keepAlive: false,
                },
            },
            {
                path: "schedule",
                name: "eurofins-sensing-schedule",
                component: () => import("@/views/eurofins/sensing/schedule.vue"),
                meta: {
                    title: "开始检测",
                    icon: "detection",
                },
            },
            {
                path: "submit",
                name: "eurofins-sensing-submit",
                component: () => import("@/views/eurofins/sensing/submit.vue"),
                meta: {
                    title: "提交报告",
                    icon: "report",
                },
            },
            {
                path: "submit-doc/:id(\\d+)?",
                name: "eurofins-sensing-submit-doc",
                hidden: true,
                component: () => import("@/views/eurofins/sensing/submit-doc.vue"),
                meta: {
                    title: "文档提交",
                },
            },
            {
                path: "order",
                name: "eurofins-sensing-order",
                component: () => import("@/views/eurofins/sensing/order.vue"),
                meta: {
                    title: "全部订单",
                    icon: "eurofins-order",
                },
            },
            {
                path: "order-detail/:id(\\d+)?",
                name: "eurofins-sensing-order-detail",
                hidden: true,
                component: () => import("@/views/eurofins/sensing/order-detail.vue"),
                meta: {
                    title: "方案详情",
                    icon: "order",
                    activeMenu: "/eurofins/sensing/order",
                },
            },
        ],
    },
    {
        path: "/eurofins/tool",
        name: "eurofins-tool",
        meta: {
            title: "销售管理",
            icon: "tool",
            parent: "eurofins",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/eurofins/tool/promotion",
        children: [
            {
                path: "promotion",
                name: "eurofins-tool-promotion",
                component: () => import("@/views/eurofins/tool/promotion.vue"),
                meta: {
                    title: "销售推广",
                    icon: "promotion",
                },
            },
        ],
    },
    {
        path: "/eurofins/price",
        name: "eurofins-price",
        meta: {
            title: "价格管理",
            icon: "price",
            parent: "eurofins",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/eurofins/price/discount",
        children: [
            {
                path: "discount",
                name: "eurofins-price-discount",
                component: () => import("@/views/eurofins/price/discount.vue"),
                meta: {
                    title: "折扣配置",
                    icon: "discount",
                },
            },
            {
                path: "member",
                name: "eurofins-price-member",
                component: () => import("@/views/eurofins/price/member.vue"),
                meta: {
                    title: "会员管理",
                    icon: "user",
                },
            },
        ],
    },
];
