export default [
    {
        path: "/pms",
        name: "pms__root__",
        redirect: {
            name: "pms-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "pms",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "pms-index",
                component: () => import("@/views/pms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/pms/service",
        name: "pms_service",
        meta: {
            title: "生产服务",
            icon: "service",
            parent: "pms",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/pms/service/sim",
        children: [
            {
                path: "sim",
                name: "pms-service-sim",
                component: () => import("@/views/pms/service/sim.vue"),
                meta: {
                    title: "流量续费",
                    icon: "sim",
                    affix: false,
                },
            },
        ],
    },
];
