export default [
    {
        path: "/omp",
        name: "omp__root__",
        redirect: {
            name: "fms-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "omp",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "omp-index",
                component: () => import("@/views/omp/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/omp/titan",
        name: "titan",
        meta: {
            title: "全局设置",
            icon: "atom",
            parent: "omp",
            permission: "omp_manage_config",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/omp/titan/titanconf",
        children: [],
    },
    {
        path: "/omp/device",
        name: "omp-device",
        meta: {
            title: "设备管理",
            icon: "device",
            parent: "omp",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "omp-device-sim",
        },
        children: [],
    },
    {
        path: "/omp/enterprise",
        name: "omp-enterprise",
        meta: {
            title: "企业管理",
            icon: "enterprise",
            parent: "omp",
            permission: "omp_manage_enterprise",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "omp-enterprise",
        },
        children: [],
    },
    {
        path: "/omp/account",
        name: "omp-account",
        meta: {
            title: "账户管理",
            icon: "user",
            parent: "omp",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "omp-account-super",
        },
        children: [],
    },
];
