export default {
    default_title: "Product Select",

    must_select_version: "Please select the version",
    search_tip: "Please enter the product name keyword or code",
    category: "Product Category",
    count_prefix: "Selected ",
    count_suffix: " goods",
    select_tip: "Please select the product first",

    table: {
        goods: "Product",
        sn: "SN Code",
        version: "Version",
        quality: "Quantity",
        unit_price: "Unit Price",
        op: "Operation",
    },
};
