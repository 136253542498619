export default {
    address: {
        add: "Add",
        search: "Search Address",
        empty: "No address found",
        delete: "Delete",
        edit: "edit",

        dialog: {
            edit: "Edit Address",
            add: "Add Address",
            receiver: "Receiver",
            phone: "Phone",
            address: "Address",
            save: "Save",
            cancel: "Cancel",
        },

        confirm: "Confirm",

        delete_confirm: "Are you sure to delete this address?",
        delete_success: "Address deleted successfully",

        receiver_error: "Please enter the receiver name",
        receiver_short_error: "Receiver name length cannot be less than 2",
        phone_error: "Please enter the phone number",
        phone_format_error: "Phone number format error",
        phone_length_error: "Please enter a valid phone number",
        address_error: "Please enter the address",

        add_success: "Add address successfully",
        edit_success: "Edit address successfully",

        abroad: "Abroad",
        within_china: "China",
    },
};
