import dayjs from "dayjs";
import { ElNotification } from "element-plus";
import Common from "@deepberry/common/data/common.json";
import { getCdnLink as _getCdnLink } from "@deepberry/common/js/utils";

export function getCdnLink(url) {
    if (url?.startsWith("http")) {
        return url;
    } else {
        return _getCdnLink(url);
    }
}

export function formatDate(value, mode = "date") {
    if (value) {
        if (mode === "date") {
            return dayjs(value).format("YYYY-MM-DD");
        }
        if (mode === "time") {
            return dayjs(value).format("HH:mm:ss");
        }
        if (mode === "hour") {
            return dayjs(value).format("HH:mm");
        }
        if (mode === "datetime") {
            return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
        }
    }
}

/**
 * 去除对象空值
 * @param {*} obj
 * @returns
 */
export function removeEmpty(obj) {
    const newObj = {};
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

/**
 * 去除对象空值 null && undefined 用于修改接口，空字符串为删除当前字段的值不能去掉
 * @param {*} obj
 * @returns
 */
export function removeEmptyEdit(obj) {
    const newObj = {};
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

/**
 * 头像地址
 * @param {*} url
 */
export function avatarUrl(url) {
    if (url) {
        if (url.startsWith("http")) {
            return url;
        }
        return "https://cdn.deepberry.cn/" + url;
    }
    return require("../assets/img/common/logo.svg");
}

/**
 * 复制文本
 */
export function copyText(text) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        ElNotification({
            title: "复制成功",
            message: text,
            type: "success",
        });
    } else {
        const input = document.createElement("input");
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        ElNotification({
            title: "复制成功",
            message: text,
            type: "success",
        });
    }
}

// params 不确定的对象
export function removeEmptyParams(params) {
    const newParams = {};
    for (const key in params) {
        if (params[key] !== undefined && params[key] !== "") {
            newParams[key] = params[key];
        }
    }
    return newParams;
}

// 时间转换
export function formatTime(time, format = "YYYY-MM-DD HH:mm:ss") {
    const _time = new Date(time);
    return dayjs(_time).format(format);
}

// 为空时不会处理
// 默认使用88*88方形，可返回方形(传递单数字)或者指定任意居中剪裁尺寸(传递数组)或指定任意预设样式(传递字符串)
export function getThumbnail(url, size = 88, replace = true) {
    if (!url) return "";

    if (replace) {
        url = url.replace("http://deepberry.oss-cn-zhangjiakou.aliyuncs.com/", Common.__cdn);
    }

    if (Array.isArray(size)) {
        url += `?x-oss-process=image/auto-orient,1/resize,m_fill,w_${size[0]},h_${size[1]}/quality,Q_100`;
    } else if (isNaN(size)) {
        url += `?x-oss-process=style/${size}`;
    } else {
        url += `?x-oss-process=image/auto-orient,1/resize,m_fill,w_${size},h_${size}/quality,Q_100`;
    }

    return url;
}

export function getVideoThumbnail(url, size = 88, replace = true) {
    if (!url) return "";

    if (replace) {
        url = url.replace("http://deepberry.oss-cn-zhangjiakou.aliyuncs.com/", Common.__cdn);
    }

    if (Array.isArray(size)) {
        url += `?x-oss-process=video/snapshot,t_0,f_jpg,w_${size[0]},h_${size[1]}`;
    } else {
        url += `?x-oss-process=video/snapshot,t_0,f_jpg,w_${size},h_${size}`;
    }

    return url;
}

// 数组转tree
export function array2Tree(
    array,
    params = { key: "id", parentKey: "parent_id", parentValue: 0, childrenKey: "children" }
) {
    const tree = [];
    // 在数组中查找具有指定父ID的所有子项
    for (let i = 0; i < array.length; i++) {
        if (~~array[i][params.parentKey] === ~~params.parentValue) {
            // 递归调用，将子项转换为子树
            const children = array2Tree(array, { ...params, parentValue: array[i][params.key] });

            if (children.length) {
                array[i][params.childrenKey] = children;
            }

            tree.push(array[i]);
        }
    }

    return tree;
}

// 多字段排序
export function sortBy(filed1, filed2) {
    return function (a, b) {
        if (a[filed1] === b[filed1]) {
            return a[filed2].localeCompare(b[filed2]);
        }
        return a[filed1].localeCompare(b[filed1]);
    };
}

// 导出csv
export function exportCsv(list, titles = []) {
    if (!titles.length) return list;
    const keys = titles.map((item) => item.key);
    const labels = titles.map((item) => item.label);
    const mapList = [];
    list.forEach((item) => {
        const newItem = [];
        keys.forEach((key) => {
            newItem.push(item[key]);
        });
        mapList.push(newItem.join(","));
    });
    mapList.unshift(labels.join(","));
    return mapList.join("\r\n");
}

// 导出xlsx
export function exportXlsx(list, titles = []) {
    if (!titles.length) return list;
    const keys = titles.map((item) => item.key);
    const labels = titles.map((item) => item.label);
    const mapList = [];
    list.forEach((item) => {
        const newItem = [];
        keys.forEach((key) => {
            newItem.push(item[key]);
        });
        mapList.push(newItem);
    });
    mapList.unshift(labels);
    return mapList;
}

export function getIconUrl(icon = "default", type = "node-props") {
    return getCdnLink(`cms/img/common/${type}/${icon}.svg`);
}

// 是否只保留remainKey
export function arr2map({ array = [], key = "key", remainKey = "" } = {}) {
    if (
        !array?.length ||
        !array.every((item) => key in item) ||
        (remainKey && !array.every((item) => remainKey in item))
    ) {
        // console.log("【参数错误】", array, key, remainKey);
        return {};
    }
    return array.reduce((acc, cur) => {
        return { ...acc, [cur[key]]: remainKey ? cur[remainKey] : cur };
    }, {});
}

export function map2arr(map) {
    return Object.entries(map).map(([key, value]) => {
        return {
            name: value,
            key: key,
        };
    });
}

// 去除富文本内容中的base64图片  Tinymce
export function removeBase64Img(content) {
    // img左右可能是转义符 &lt;img&gt; 或者 <img>
    return content.replace(/(&lt;|<)img[^>]*?src="data:image[^>]*?(&gt;|>)/gi, "");
}

export function goodsCoverUrl(url, size = 0) {
    if (url) {
        if (url.startsWith("http")) {
            return size ? getThumbnail(url, size * 2) : url;
        }
        return size ? getThumbnail(getCdnLink(url), size * 2) : getCdnLink(url);
    }
    return require("../assets/img/common/logo.svg");
}

export function formatCNY(cny) {
    return typeof cny == "number"
        ? cny.toLocaleString("zh-CN", {
              style: "currency",
              currency: "CNY",
          })
        : 0;
}
/**
 * 将阿拉伯数字价格转换为中文价格
 * @param price 阿拉伯数字价格
 * @returns 中文价格
 */
export function getChinesePrice(price) {
    if (price === 0) return "零元整";
    if (price >= 1e12) return "整数位已超过最大值";

    const CHINESE_NUMBER_MAP = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    const CHINESE_UNIT_MAP = ["", "拾", "佰", "仟"];
    const CHINESE_BIG_UNIT_MAP = ["", "万", "亿"];
    const CHINESE_SMALL_UNIT_MAP = ["角", "分", "厘", "毫"];

    const priceStr = price.toString();
    const priceArr = priceStr.split(".");
    const integer = priceArr[0];
    const decimal = priceArr[1];

    let chineseIntegerPrice = "";
    let zeroCount = 0;

    for (let i = 0; i < integer.length; i++) {
        const num = +integer[i];
        const unit = integer.length - i - 1; // 当前数字的单位
        const quotient = Math.floor(unit / 4); // 1w为进位单位, 除 4 即为 万 亿
        const remainder = unit % 4; // 1w为进位单位, 取模 4 即为 个 十 百 千

        if (num === 0) {
            zeroCount++;
        } else {
            // 处理前置的零
            if (zeroCount > 0) chineseIntegerPrice += CHINESE_NUMBER_MAP[0];
            zeroCount = 0;
            chineseIntegerPrice += CHINESE_NUMBER_MAP[num] + CHINESE_UNIT_MAP[remainder];
        }
        if (remainder === 0 && zeroCount < 4) {
            chineseIntegerPrice += CHINESE_BIG_UNIT_MAP[quotient];
        }
    }

    // 价格为小数时，整数部分不显示
    if (price < 1) chineseIntegerPrice = "";
    else chineseIntegerPrice += "元";

    let chineseDecimalPrice = "";

    if (!decimal) {
        chineseDecimalPrice = "整";
    } else {
        let hasZero = false;
        for (let i = 0; i < decimal.length; i++) {
            const num = +decimal[i];
            if (num) chineseDecimalPrice += CHINESE_NUMBER_MAP[num] + CHINESE_SMALL_UNIT_MAP[i];
            else hasZero = true;
        }

        if (chineseIntegerPrice && hasZero) chineseIntegerPrice += "零";
    }

    return chineseIntegerPrice + chineseDecimalPrice;
}

export function formatDiscount(num) {
    if (isNaN(parseFloat(num)) || parseFloat(num) <= 0 || parseFloat(num) >= 1) {
        return "无折扣";
    }
    return parseFloat(num) * 10 + "折";
}
