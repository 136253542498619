import { $cms } from "@/utils/https.js";

export function createUserMessage(id, data) {
    return $cms().post(`/api/cms/titan/user/message/to/user`, data, {
        params: {
            id,
        },
    });
}
export function createOrgMessage(id, data) {
    return $cms().post(`/api/cms/titan/user/message/to/organization`, data, {
        params: {
            id,
        },
    });
}
export function getMessages(params) {
    return $cms().get(`/api/cms/titan/user/message`, { params });
}
export function updateMessage(id, data) {
    return $cms().put(`/api/cms/titan/user/message/${id}`, data);
}
export function deleteMessage(id) {
    return $cms().delete(`/api/cms/titan/user/message`, {
        params: {
            id,
        },
    });
}

// 搜索账号列表
export function getUsers(params) {
    return $cms().get(`/api/cms/titan/user`, {
        params,
    });
}
// 获取组织列表
export function getOrganizations(params) {
    return $cms().get(`/api/cms/titan/organization`, {
        params,
    });
}

//内部短消息
// 全部已读
export function readAllMessage() {
    return $cms().put(`/api/cms/admin/message/count`);
}
// 获取未读消息
export function getMessageUnRead() {
    return $cms().get(`/api/cms/admin/message/count`);
}
// 获取收件箱列表
export function getInboxMessages(params) {
    return $cms().get(`/api/cms/admin/message/inbox`, { params });
}
// 获取发件箱列表
export function getOutboxMessages(params) {
    return $cms().get(`/api/cms/admin/message/outbox`, { params });
}
// 删除消息
export function deleteAdminMessage(ids) {
    return $cms().delete(`/api/cms/admin/message`, { params: { id: ids } });
}
// 撤回消息
export function revokeMessage(id) {
    return $cms().delete(`/api/cms/admin/message/revoke`, { params: { id } });
}
// 修改消息
export function updateAdminMessage(id, data) {
    return $cms().put(`/api/cms/admin/message/${id}`, data);
}
// 发送消息
export function sendMessage(data, params) {
    return $cms().post(`/api/cms/admin/message`, data, { params });
}
