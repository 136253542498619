/**
 * 左侧导航多语言
 */
export default {
    home: "首页",
    manage: "管理中心",
    control: "控制中心",
    common: {
        account: {
            name: "个人中心",

            profile: "资料设置",
            message: "通知消息",
            address: "地址管理",
            wechat_bind: "微信绑定",
        },
    },
    oem: {
        // 订单管理组
        order_management: {
            name: "订单管理",

            new: "立刻下单",
            mine: "我的订单",
            edit: "编辑订单",
            detail: "订单详情",
        },
    },
};
