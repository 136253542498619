export default [
    {
        path: "/__redirect",
        name: "__redirect__",
        component: () => import("@/layouts/default.vue"),
        hidden: true, // 如果设置为true，则不会显示在菜单中
        meta: {
            isPublic: true,
            title: "重定向",
        },
        children: [
            {
                path: "/__redirect/:path(.*)",
                component: () => import("@/views/_/index.vue"),
            },
        ],
    },
    {
        path: "/",
        name: "__root__",
        hidden: true,
        meta: {
            isPublic: true,
            title: "深莓管理平台",
        },
        children: [
            {
                path: "/",
                name: "home",
                component: () => import("@/views/_/home.vue"),
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/_/login.vue"),
        hidden: true,
        meta: {
            isPublic: true,
            title: "登录 - 深莓管理平台",
        },
    },
    // {
    //     path: "/:catchAll(.*)*",
    //     redirect: "/",
    //     hidden: true,
    // },
    // { path: "/:pathMatch(.*)*", name: "NotFound", component: () => import("@/views/_/NotFound.vue") },
    // { path: "/forbidden", name: "NotAuthenticated", component: () => import("@/views/_/NotAuthenticated.vue") },
];
