import { $cms } from "@/utils/https.js";

// 完成订单
export function completeOrder(id) {
    return $cms().put(`/api/cms/manage/mall/handmade_order/${id}/complete`);
}

// 获取订单列表
export function getOrderList(params) {
    return $cms().get(`/api/cms/manage/mall/handmade_order`, { params });
}

// 备注订单
export function remarkOrder(id, data) {
    return $cms().put(`/api/cms/manage/mall/handmade_order/${id}/remark`, data);
}
