import { getGoodsCategories } from "@/service/goods_category";
import { array2Tree } from "@/utils";
import { cloneDeep } from "lodash";

const state = {
    categories: [],
    categoriesMap: {},
    categoryTree: [],
};
const mutations = {
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },
    SET_CATEGORIES_MAP(state, categoriesMap) {
        state.categoriesMap = categoriesMap;
    },
    SET_CATEGORY_TREE(state, categoryTree) {
        state.categoryTree = categoryTree;
    },
};
const actions = {
    async loadCategories({ commit, state }) {
        if (state.categories.length) {
            return;
        }
        const resp = await getGoodsCategories({ _no_page: 1 });
        const data = resp.data.data.map((item) => {
            return {
                id: item.category_id,
                name: item.category_name,
                parent_id: item.parent_id,
            };
        });
        commit("SET_CATEGORIES", data);
        commit(
            "SET_CATEGORIES_MAP",
            data.reduce((acc, cur) => {
                acc[cur.id] = cur.name;
                return acc;
            }, {})
        );
        commit("SET_CATEGORY_TREE", array2Tree(cloneDeep(data)));
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
