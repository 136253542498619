export default {
    address: {
        add: "添加地址",
        search: "搜索地址",
        empty: "没有符合条件的地址",
        delete: "删除",
        edit: "编辑",

        dialog: {
            edit: "编辑地址",
            add: "添加地址",
            receiver: "收件人",
            phone: "手机号",
            address: "地址",
            save: "保存",
            cancel: "取消",
        },

        confirm: "确认",

        delete_confirm: "确认删除该地址吗？",
        delete_success: "删除地址成功",

        receiver_error: "请填写收件人姓名",
        receiver_short_error: "收件人姓名长度不能小于 2",
        phone_error: "请填写联系方式",
        phone_format_error: "联系方式格式错误",
        phone_length_error: "请填写正确的电话号码",
        address_error: "请填写地址",

        add_success: "添加地址成功",
        edit_success: "编辑地址成功",

        abroad: "国外",
        within_china: "国内",
    },
};
