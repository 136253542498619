<template>
    <router-view />
</template>

<script>
import { mapMutations } from "vuex";
import { Local } from "@/utils/storage";
import { debounce } from "lodash";
export default {
    name: "App",
    created() {
        this.initMobile();
        window.addEventListener("resize", () => {
            this.initMobile();
        });
        this.initSidebar();
    },
    methods: {
        ...mapMutations({
            setSidebarStatus: "app/SET_SIDEBAR_STATUS",
            setMobile: "app/SET_MOBILE",
        }),
        initMobile: debounce(function () {
            const userAgent = navigator.userAgent;
            const isMobile = /Mobi|Android/i.test(userAgent);
            this.setMobile(isMobile);
            if (isMobile) {
                // 设置body的is-mobile类
                document.body.classList.add("is-mobile");
            }
            console.log("[终端]:", isMobile ? "Phone" : "PC");
        }, 500),
        initSidebar() {
            const sidebar = Local.get("sidebarStatus") || 1;
            if (window.innerWidth < 1133) {
                this.setSidebarStatus(false);
            } else {
                this.setSidebarStatus(sidebar);
            }
        },
    },
};
</script>

<style lang="less">
@import "@/assets/css/app.less";
</style>
