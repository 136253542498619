export default {
    default_title: "产品选择",

    must_select_version: "请选择版本",
    search_tip: "请输入产品名称或编码",
    category: "产品分类",
    count_prefix: "已选择 ",
    count_suffix: " 个零配件",
    select_tip: "请先选中该产品",

    table: {
        goods: "产品",
        sn: "编码",
        version: "版本",
        quality: "数量",
        unit_price: "单价",
        op: "操作",
    },
};
